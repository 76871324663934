import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenService } from 'src/app/services/screen-size.service';
import { ComponentBase } from 'src/app/utils/component-base-class.utils';

@Component({
  selector: 'lat-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent extends ComponentBase {
  @Input() site: string;
  @Input() heading: string = null;
  @Input() headingStyleOverrides: Record<string, string> = {};
  @Input() subheading: string = null;
  @Input() subheadingStyleOverrides: Record<string, string> = {};
  @Input() imageUrl: string = null;
  @Input() imageUrlMobile: string = null;

  constructor(
    private screenService: ScreenService,
    private navigationService: NavigationService
  ) {
    super(screenService, navigationService);
  }
}
