<header class="button-nav-container">
    <nav class="navigation-bar">
        <div class="navigation-button-container" id="button-nav-community" (click)="navigateTo('/community/overview')">
            <div class="navigation-button-content">
                <span>Explore the Community</span>
            </div>
        </div>
        <div class="navigation-button-container" id="button-nav-sales" (click)="navigateTo('/for-sale/overview')">
            <div class="navigation-button-content">
                <span>Homes for Sale</span>
            </div>
        </div>
        <!-- <div
            class="navigation-button-container"
            id="button-nav-lease"
            (click)="navigateTo('/for-lease/overview')"
        >
            <div class="navigation-button-content">
                <span>Homes for Lease</span>
            </div>
        </div> -->
    </nav>
</header>