import { HomePlanData, HomeType, HomeTypeAvailability } from '../types/home';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SitePlanLegendLabel } from '../types/site-plan';

@Injectable({
    providedIn: 'root'
})
export class SitePlanService {
    constructor(private http: HttpClient) { }

    getSitePlanLegendItems(): Observable<Array<SitePlanLegendLabel>> {
        return this.http.get<Array<SitePlanLegendLabel>>(`${environment.API_URL}/siteplan-legend`);
    }
}
