import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material/material.module';
import { NavbarComponent } from './navbar/navbar.component';

import { CalloutComponent } from './callout/callout.component';
import { ErrorContentComponent } from './error-content/error-content.component';
import { FormSubmissionModalComponent } from './form-submission-modal/form-submission-modal.component';
import { FullShadowModalComponent } from './full-shadow-modal/full-shadow-modal.component';
import { HeroComponent } from './hero/hero.component';
import { PaginatedCalloutSliderComponent } from './paginated-callout-slider/paginated-callout-slider.component';
import { SliderComponent } from './slider/slider.component';
import { SitePlanComponent } from './site-plan/site-plan.component';
import { HomeSwiperComponent } from './home-swiper/home-swiper.component';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { PhotoGalleryModalComponent } from './photo-gallery-modal/gallery.component';
import { FloorPlanModalComponent } from './floorplan-modal/floorplan.component';
import { VirtualTourModalComponent } from './virtual-tour-modal/tour.component';
import { HomeCategorySelectorComponent } from './home-category-selector/category.component';

@NgModule({
  declarations: [
    HeroComponent,
    NavbarComponent,
    FormSubmissionModalComponent,
    CalloutComponent,
    SliderComponent,
    PaginatedCalloutSliderComponent,
    ErrorContentComponent,
    FullShadowModalComponent,
    SitePlanComponent,
    HomeCategorySelectorComponent,
    HomeSwiperComponent,
    PhotoGalleryModalComponent,
    FloorPlanModalComponent,
    VirtualTourModalComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    GoogleMapsModule,
    RouterModule,
    MatSelectModule,
    MatOptionModule,
    FormsModule,
    SwiperModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        disableClose: true,
        minHeight: 280
      } as MatDialogConfig
    }
  ],
  exports: [
    NavbarComponent,
    HeroComponent,
    FormSubmissionModalComponent,
    CalloutComponent,
    SliderComponent,
    PaginatedCalloutSliderComponent,
    ErrorContentComponent,
    FullShadowModalComponent,
    SitePlanComponent,
    HomeCategorySelectorComponent,
    HomeSwiperComponent,
    SwiperComponent,
    PhotoGalleryModalComponent,
    FloorPlanModalComponent,
    VirtualTourModalComponent
  ],
  entryComponents: []
})
export class SharedModule { }
