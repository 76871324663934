import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { ChildrenOutletContexts } from '@angular/router';
// import { swapComponent } from './animations';
import SwiperCore, { Pagination, Virtual } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination, Virtual]);

@Component({
  selector: 'lat-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Lakeside at Trappe';
  isSplash = true;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngAfterViewInit() {
    this.route.url.subscribe(obs => {
      if (obs.length === 1) {
        this.isSplash = true;
      } else {
        this.isSplash = false;
      }
    });
  }

  onRouterActivate(event: any) { }
}
