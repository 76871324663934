import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'kwh-full-shadow-modal',
  templateUrl: './full-shadow-modal.component.html',
  styleUrls: ['./full-shadow-modal.component.scss']
})
export class FullShadowModalComponent implements OnInit {
  @ViewChild('overlay') overlay: ElementRef;
  @ViewChild('closeIcon') closeIcon: ElementRef;

  constructor() {}

  /**
   * In most cases, you should pass in an anonymous function like this
   * or you might get ExpressionChangedAfterItHasBeenCheckedError
   *
   * MyComponent {
   *   closeModal = () => { this.isModalOpen = false; }
   * }
   *
   * <kwh-full-shadow-modal [onClose]="closeModal"></kwh-full-shadow-modal>
   */
  @Input() onClose: any;

  ngOnInit(): void {}

  public handleOnClose(e: Event = null): void {
    console.log('closing');
    if (
      e.target === this.overlay.nativeElement ||
      e.target === this.closeIcon.nativeElement
    ) {
      this.onClose?.();
    }
  }
}
