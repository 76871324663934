import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

const MOBILE_BP_MAX = '(max-width: 599px)';
const TABLET_BP_MIN = '(min-width: 600px)';
const TABLET_BP_MAX = '(max-width: 959px)';
const DEKSTOP_BP_MIN = '(min-width: 960px)';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {
  isMobile$ = new BehaviorSubject(false);
  isTablet$ = new BehaviorSubject(false);
  isDesktop$ = new BehaviorSubject(false);

  constructor(private observer: BreakpointObserver) {
    this.isMobile().subscribe((mobileBP: BreakpointState) => {
      this.isMobile$.next(mobileBP.matches);
    });
    this.isTablet().subscribe((tabletBP: BreakpointState) => {
      if (
        tabletBP.breakpoints[TABLET_BP_MIN] &&
        tabletBP.breakpoints[TABLET_BP_MAX]
      ) {
        this.isTablet$.next(true);
      } else if (this.isTablet$.getValue()) {
        this.isTablet$.next(false);
      }
    });
    this.isDesktop().subscribe((desktopBP: BreakpointState) => {
      this.isDesktop$.next(desktopBP.matches);
    });
  }

  isMobile(): Observable<BreakpointState> {
    return this.observer.observe(MOBILE_BP_MAX);
  }

  isTablet(): Observable<BreakpointState> {
    return this.observer.observe([TABLET_BP_MIN, TABLET_BP_MAX]);
  }

  isDesktop(): Observable<BreakpointState> {
    return this.observer.observe(DEKSTOP_BP_MIN);
  }
}
