<div class="floorplan-modal" *ngIf="active">
    <div class="floorplan-modal-content">
        <div class="floorplan-modal-photo-container">
            <div class="spinner-container" *ngIf="!loaded">
                <mat-spinner></mat-spinner>
            </div>
            <img [ngStyle]="{ display: loaded ? 'unset' :  'none' }" class="floorplan-modal-photo"
                (load)="photoLoaded($event)" [src]="floorPlanImages[index]?.url" />
        </div>
        <div class="floorplan-modal-controls">
            <div class="floorplan-modal-nav-container">
                <div (click)="prev()" class="floorplan-modal-nav floorplan-modal-nav-prev">
                    <img src="/assets/icons/arrows/Arrow_Left_Orange.svg">
                </div>
                <div (click)="next()" class="floorplan-modal-nav floorplan-modal-nav-next">
                    <img src="/assets/icons/arrows/Arrow_Right_Orange.svg">
                </div>
            </div>
            <div class="floorplan-modal-counter">{{ floorPlanImages[index]?.caption }}</div>
        </div>
    </div>
    <div class="floorplan-modal-close-container">
        <button class="floorplan-modal-close-button" (click)="closeModal()">Close</button>
    </div>
</div>