<div class="splash-header-container">
    <a href="https://tworiverslakesidetouch.brookfielddc.com/" class="splash-logo-link"><img id="splash-logo"
            src="/assets/logos/lakeside-at-trappe/LakesideLogo_FullColor.svg" /></a>
    <h1 class="splash-header">Welcome to your</h1>
    <h2 class="splash-subheader">Everyday Getaway</h2>
    <h3 class="splash-tagline">Lets explore how you can play, live and work all within a vibrant lakeside community!
    </h3>
</div>
<div class="splash-background">
    <img src="{{ splashData.backgroundImages[0] }}" />
</div>
<lat-splash-nav></lat-splash-nav>