import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lat-callout',
  templateUrl: './callout.component.html',
  styleUrls: ['./callout.component.scss']
})
export class CalloutComponent implements OnInit {
  @Input() heading: string;
  @Input() buttonLink: string;
  @Input() buttonText: string;

  constructor() {}

  ngOnInit(): void {}
}
