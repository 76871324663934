import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SwiperComponent } from 'swiper/angular';

import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenService } from 'src/app/services/screen-size.service';
import { ComponentBase } from 'src/app/utils/component-base-class.utils';
import { EventHint, HomeModals, HomeSwiperInitialSection, HomeSwiperSection } from 'src/app/types/home-swiper';
import { PhotoGalleryModalComponent } from '../photo-gallery-modal/gallery.component';
import { FloorPlanModalComponent } from '../floorplan-modal/floorplan.component';
import { VirtualTourModalComponent } from '../virtual-tour-modal/tour.component';
import { PhotoEntry } from '../photo-gallery-modal/gallery.constants';

@Component({
    selector: 'lat-home-swiper',
    templateUrl: './home-swiper.component.html',
    styleUrls: ['./home-swiper.component.scss']
})
export class HomeSwiperComponent extends ComponentBase {
    @ViewChild('homesSwiper')
    swiper: SwiperComponent;
    @ViewChild('backgroundImage', { read: ElementRef<HTMLImageElement> })
    backgroundImage: ElementRef<HTMLImageElement>;
    @ViewChild('photoGalleryModal', { read: PhotoGalleryModalComponent })
    photoGalleryModal: PhotoGalleryModalComponent;
    @ViewChild('floorplanModal', { read: FloorPlanModalComponent })
    floorplanModal: FloorPlanModalComponent;
    @ViewChild('virtualTourModal', { read: VirtualTourModalComponent })
    virtualTourModal: VirtualTourModalComponent;

    @Input() ready: boolean = false;
    _homes: Array<HomeSwiperSection> = [];
    get homes(): Array<HomeSwiperSection> {
        return this._homes;
    };
    @Input() set homes(arr: Array<HomeSwiperSection>) {
        this._homes = arr;
        if (arr.length > 0) {
            this.setup();
            this.swiper.updateInitSwiper({
                slidesPerView: arr.length > 1 ? 1.5 : 1
            });
            this.swiper.updateSwiper({})
            this.changeObserver.detectChanges();
        }
    };

    @Input() initialSection?: HomeSwiperInitialSection;
    @Input() forceSubheadingAsTagline: boolean = false;

    activeSection: HomeSwiperSection | HomeSwiperInitialSection;
    sectionBackgroundLoaded = false;
    index: number = 0;
    swiperOptions = {
        navigation: {
            enabled: true,
            nextEl: 'homes-swiper-nav-right',
            prevEl: 'homes-swiper-nav-left'
        },
        centeredSlides: false,
        slidesPerView: 1.5,
        spaceBetween: 25
    }
    currentHomePhotos: Array<PhotoEntry> = [];
    currentHomeFloorplan: Array<PhotoEntry> = [];
    currentHomeVirtualTourLink: string = '';

    @Output('navigationPressed')
    navigationPressedEventEmitter: EventEmitter<EventHint> = new EventEmitter<EventHint>();

    constructor(
        private changeObserver: ChangeDetectorRef,
        private screenService: ScreenService,
        private navigationService: NavigationService
    ) {
        super(screenService, navigationService);
    }

    ngOnInit(): void { this.setup() }

    setup(): void {
        this.activeSection = this.initialSection !== undefined ? this.initialSection : this.homes.at(0);
        this.populateModals();
        this.changeObserver.detectChanges();
    }

    emitOpenModal(hint: EventHint): void {
        switch (hint.id) {
            case HomeModals.Photos:
                this.photoGalleryModal.openModal();
                break;
            case HomeModals.Floorplan:
                this.floorplanModal.openModal();
                break;
            case HomeModals.VirtualTour:
                this.virtualTourModal.openModal();
                break;
        }
    }
    emitNavigationPressed(hint: EventHint): void {
        this.navigationPressedEventEmitter.emit(hint);
    }

    setSectionDataIndex(idx: number): void {
        if (this.initialSection) {
            this.activeSection =
                (idx === 0 ? this.initialSection : this.homes[idx - 1]) ??
                this.homes[this.homes.length - 1];
        } else {
            this.activeSection = this.homes[idx] ?? this.homes[0];
        }
    }

    onSlideChange(): void {
        this.index = this.swiper.swiperRef.activeIndex;
        this.setSectionDataIndex(this.index);
        this.populateModals();

        if (this.backgroundImage.nativeElement.src !== this.activeSection?.backgroundImage) {
            this.sectionBackgroundLoaded = false;
        }
        this.changeObserver.detectChanges();
    }

    populateModals(): void {
        if (this.activeSection.modals.length > 0) {
            const photoModal = this.activeSection.modals.find(elem => elem.id === HomeModals.Photos);
            const floorplanModal = this.activeSection.modals.find(elem => elem.id === HomeModals.Floorplan);
            const virtualTourModal = this.activeSection.modals.find(elem => elem.id === HomeModals.VirtualTour);
            this.currentHomePhotos = photoModal ? photoModal['photos'] : [];
            this.currentHomeFloorplan = floorplanModal ? floorplanModal['floorPlanImages'] : [];
            this.currentHomeVirtualTourLink = virtualTourModal ? virtualTourModal['virtualTourIframeLink'] : '';
        }
    }

    onNextButtonClick(event: MouseEvent): void {
        if (this.index < this.homes.length - (this.initialSection ? 0 : 1)) {
            this.index++;
            this.swiper.swiperRef.slideNext();
        }
    }
    onPrevButtonClick(event: MouseEvent): void {
        if (this.index > 0) {
            this.index--;
        }
        this.swiper.swiperRef.slidePrev();
    }

    onSectionBackgroundLoaded(event: Event): void {
        this.sectionBackgroundLoaded = true;
        this.changeObserver.detectChanges();
    }

    onSlideNextTransitionStart(): void {
        if (this.index >= this.homes.length) {
            this.swiper.setIndex(this.homes.length - (this.initialSection ? 0 : 1));
        }
    }

    public setIndex(idx: number): void {
        this.index = idx;
        this.setSectionDataIndex(this.index);
        this.swiper.setIndex(idx);
        this.changeObserver.detectChanges();
    }
}
