<section id="hero" class="{{ site }}">
  <div
    *ngIf="isDesktop"
    class="hero-background"
    [ngStyle]="{ 'background-image': 'url(' + imageUrl + ')' }"
  >
    <div class="hero-heading-bumper"></div>
    <h1 *ngIf="heading" class="hero-heading"
      [ngStyle]="headingStyleOverrides">{{ heading }}</h1>
    <h2 *ngIf="subheading" class="hero-subheading"
      [ngStyle]="subheadingStyleOverrides">{{ subheading }}</h2>
  </div>
  <div *ngIf="isMobile || isTablet">
    <div class="hero-mobile-bump"></div>
    <div
      *ngIf="isMobile || isTablet"
      class="hero-background"
      [ngStyle]="{ 'background-image': 'url(' + imageUrlMobile + ')' }"
    >
      <h1 *ngIf="heading" class="hero-heading"
        [ngStyle]="headingStyleOverrides">{{ heading }}</h1>
      <h2 *ngIf="subheading" class="hero-subheading"
        [ngStyle]="subheadingStyleOverrides">{{ subheading }}</h2>
    </div>
  </div>
</section>
