import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/splash/splash.module').then(m => m.SplashModule)
  },
  {
    path: 'community',
    loadChildren: () =>
      import('./features/community/community.module').then(m => m.CommunityModule)
  },
  {
    path: 'for-lease',
    loadChildren: () =>
      import('./features/for-lease/for-lease.module').then(m => m.ForLeaseModule)
  },
  {
    path: 'for-sale',
    loadChildren: () =>
      import('./features/for-sale/for-sale.module').then(m => m.ForSaleModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
