import { Optional } from '@angular/core';

import { NavigationService } from '../services/navigation.service';
import { ScreenService } from '../services/screen-size.service';
import { BreakpointsUtils } from './breakpoints-base-class.utils';

/**
 * Do any desired component extending here.
 */

export class ComponentBase extends BreakpointsUtils {
  constructor(
    @Optional() private _screenService: ScreenService,
    @Optional() private _navigationService: NavigationService
  ) {
    super(_screenService);
  }

  public navigateTo(link: string) {
    this._navigationService.navigateTo(link);
  }
}
