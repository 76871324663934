export enum HomeModals {
    Photos = 'Photos',
    VirtualTour = 'Virtual Tour',
    Floorplan = 'Floorplan'
}

export type EventHint = {
    id: string,
    [x: string]: any
}

export type SwiperCardStyle = {
    'background-color': string,
    height: string,
    width: string;
}

export type SwiperCardInfoChip = {
    label: string,
    value: string,
}

export type SwiperCard = {
    style: SwiperCardStyle,
    title: string,
    subtitle: string,
    info: Array<SwiperCardInfoChip>,
    icon: string
};

export type HomeSwiperSection = {
    card: SwiperCard,
    backgroundImage: string,
    heading: string,
    subheading: string,
    // Array of ids to instruct the swiper to emit modalOpen events for 
    modals: Array<EventHint & { label: string }>,
    // Array of ids to instruct the swiper to emit navigationPressed events for
    navigationButtons: Array<EventHint & { label: string }>
};

export type HomeSwiperInitialSection = HomeSwiperSection & {
    card: SwiperCard & { underlay: string, loading: string };
}