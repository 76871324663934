import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/common/shared.module';
import { SplashNavComponent } from './subfeatures/splashnav/splashnav.component';
import { SplashRoutingModule } from './splash-routing.module';

import { SplashComponent } from './splash.component';

@NgModule({
  declarations: [SplashComponent, SplashNavComponent],
  imports: [CommonModule, SharedModule, SplashRoutingModule],
  exports: [SplashComponent]
})
export class SplashModule {}
