<div class="background">
    <img class="category-background" src="/assets/icons/plant/plant-cream.svg" />
</div>
<div class="category-container">
    <div class="category-text-container">
        <h1 class="category-heading">Find the Right Fit</h1>
        <h2 class="category-subheading">For Your Family</h2>
        <p class="category-tagline">Select an option below to explore our incredible home designs.</p>
    </div>
    <div class="category-tiles" [ngStyle]="{ 'justify-content':  tiles.length > 1 ? 'space-evenly' : 'center' }">
        <div *ngFor="let tile of tiles" class="category-tile"
            [ngStyle]="{ 'background-image': tiles.indexOf(tile) % 2 > 0 ? tile.imageAlt : tile.image }"
            (click)="selectSection(tile.category)">
            <div class="category-inner">
                <span>{{ tile.label }}</span>
            </div>
        </div>
    </div>
</div>