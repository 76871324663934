import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenService } from 'src/app/services/screen-size.service';
import { ComponentBase } from 'src/app/utils/component-base-class.utils';

@Component({
  selector: 'lat-splash-nav',
  templateUrl: './splashnav.component.html',
  styleUrls: ['./splashnav.component.scss']
})
export class SplashNavComponent extends ComponentBase implements OnInit {
  constructor(
    private screenService: ScreenService,
    private navigationService: NavigationService
  ) {
    super(screenService, navigationService);
  }

  ngOnInit(): void {}
}
