
import { ChangeDetectorRef, Component, EventEmitter, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenService } from 'src/app/services/screen-size.service';
import { ComponentBase } from 'src/app/utils/component-base-class.utils';

@Component({
    selector: 'lat-virtual-tour-modal',
    templateUrl: './tour.component.html',
    styleUrls: ['./tour.component.scss']
})
export class VirtualTourModalComponent extends ComponentBase {
    @Input() iframeUrl: string;

    active: boolean = false;
    sanitizedUrl: SafeUrl = null;

    modalOpenStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private screenService: ScreenService,
        private navigationService: NavigationService,
        private observer: ChangeDetectorRef,
        private sanitizer: DomSanitizer
    ) {
        super(screenService, navigationService);
    }

    ngOnInit(): void {
    }

    public openModal(): void {
        this.active = true;
        this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl);
        this.observer.detectChanges();
        this.modalOpenStateChanged.emit(true);
    }

    public closeModal(): void {
        this.active = false;
        this.sanitizedUrl = null;
        this.observer.detectChanges();
        this.modalOpenStateChanged.emit(false);
    }
}