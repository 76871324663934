import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenService } from 'src/app/services/screen-size.service';
import { PlatformService } from 'src/app/services/platform.service';
import { ComponentBase } from 'src/app/utils/component-base-class.utils';
import { SitePlanService } from 'src/app/services/site-plan.service';
import { SitePlanLegendLabel } from 'src/app/types/site-plan';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'lat-site-plan',
    templateUrl: './site-plan.component.html',
    styleUrls: ['./site-plan.component.scss']
})
export class SitePlanComponent extends ComponentBase implements OnInit {
    lotId: number;
    hideNav: boolean = false;
    iframeOptions = {
        suppressTitle: true,
        viewport: 'touchscreen',
        suppressBottomMenu: true,
        showLegend: false
    };
    iframeUrl: SafeResourceUrl;
    legendLabels: Array<SitePlanLegendLabel> = [];

    constructor(
        private screenService: ScreenService,
        private navigationService: NavigationService,
        private observer: ChangeDetectorRef,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private platformService: PlatformService,
        private sitePlanService: SitePlanService
    ) {
        super(screenService, navigationService);
    }


    ngOnInit(): void {
        this.route.queryParamMap.subscribe(obs => {
            this.hideNav = obs.get('hideNav') === '1';
            this.lotId = Number.parseInt(obs.get('lotid'));
            this.configureIframeUrl();
            this.loadSitePlanLegend();
        });
    }

    configureIframeUrl(): void {
        const encodeOptions = obj => {
            const str = [];
            for (const p in obj)
                if (obj.hasOwnProperty(p)) {
                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                }
            return str.join("&");
        }
        const finalOptions = {
            ...this.iframeOptions,
            ...(this.lotId && { lotId: this.lotId })
        };
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            `${environment.SITE_PLAN_URL}?${encodeOptions(finalOptions)}`
        );
        this.observer.detectChanges();
    }

    loadSitePlanLegend(): void {
        this.sitePlanService
            .getSitePlanLegendItems()
            .subscribe(obs => {
                this.legendLabels = obs;
                this.observer.detectChanges();
            });
    }
}
