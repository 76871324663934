<div class="contain-modal">
  <div class="position-close">
    <img (click)="close()" src="/assets/icons/Close-icon.svg" />
  </div>
  <div class="content">
    <div class="head">Request Sent</div>
    <div class="subhead">
      The form has been successfully submitted. Keep an eye on your inbox for
      messages from our team.
    </div>
  </div>
</div>
