<div class="home-swiper-background">
    <div class="spinner-container" *ngIf="!sectionBackgroundLoaded">
        <mat-spinner></mat-spinner>
    </div>
    <img #backgroundImage [ngStyle]="{ display: sectionBackgroundLoaded ? 'unset' :  'none' }"
        (load)="onSectionBackgroundLoaded($event)" [src]="activeSection?.backgroundImage" />
</div>
<div class="home-swiper-root-container">
    <div class="home-swiper-row-container">
        <div class="home-swiper-lhs-container">
            <div class="home-swiper-text-container">
                <h2 class="home-swiper-heading">{{ activeSection.heading }}</h2>
                <h2 [ngClass]="{
                        'home-swiper-subheading': index > 0 && !forceSubheadingAsTagline,
                        'home-swiper-tagline': index === 0 || forceSubheadingAsTagline
                    }">{{ activeSection.subheading }}</h2>
            </div>
            <div *ngIf="activeSection.modals" class="home-swiper-modal-button-container">
                <button *ngFor="let modal of activeSection.modals" class="home-swiper-modal-button"
                    (click)="emitOpenModal(modal)">
                    {{ modal.label }}
                </button>
            </div>
        </div>
        <div class="home-swiper-swiper-container">
            <div (click)="onPrevButtonClick($event)" class="home-swiper-nav homes-swiper-nav-left">
                <img src="/assets/icons/arrows/Arrow_Left_Orange.svg" />
            </div>
            <swiper #homesSwiper [config]="swiperOptions" (slideChange)="onSlideChange()"
                (slideNextTransitionStart)="onSlideNextTransitionStart()">
                <ng-template *ngIf="!!initialSection" swiperSlide>
                    <div class="home-card-container" [ngStyle]="initialSection.card.style"
                        [ngClass]="{ 'home-card-active': activeSection === initialSection }">
                        <div class="home-card-inner-container"
                            [ngStyle]="{ 'background-image': 'url(' + initialSection.card.underlay + ')' }">
                            <div class="home-card-content-border"></div>
                            <div class="home-card-content-initial">
                                <div *ngIf="!ready" class="home-card-spinner-container">
                                    <mat-spinner></mat-spinner>
                                </div>
                                <div *ngIf="ready" class="home-card-instructions">{{ initialSection.card.title }}</div>
                                <div *ngIf="!ready" class="home-card-instructions">{{ initialSection.card.loading }}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template *ngFor="let home of homes" swiperSlide>
                    <div class="home-card-container" [ngStyle]="home.card.style"
                        [ngClass]="{ 'home-card-active': activeSection === home }">
                        <div class="home-card-inner-container" [ngClass]="">
                            <img *ngIf="home.card.icon !== null" class="home-card-icon" src="{{ home.card.icon }}" />
                            <div class="home-card-content-border"></div>
                            <div class="home-card-content">
                                <div class="home-card-title-container">
                                    <div class="home-card-title"
                                        [ngClass]="{ 'home-card-title-small': home.card.title.length > 25 }">{{
                                        home.card.title }}</div>
                                    <div class="home-card-subtitle">{{ home.card.subtitle }}</div>
                                </div>
                                <div class="home-card-info-chip-container">
                                    <div *ngFor="let chip of home.card.info" class="home-card-info-chip">
                                        <span class="home-card-info-chip-data">{{ chip.value }}</span>
                                        <span class="home-card-info-chip-label">{{ chip.label }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template swiperSlide>
                    <div class="home-card-bumper"></div>
                </ng-template>
            </swiper>
            <div (click)="onNextButtonClick($event)" class="home-swiper-nav home-swiper-nav-right">
                <img src="/assets/icons/arrows/Arrow_Right_Orange.svg" />
            </div>
        </div>
        <div class="home-swiper-navigation-button-container">
            <button *ngFor="let hint of activeSection.navigationButtons" class="home-swiper-navigation-button"
                (click)="emitNavigationPressed(hint)">{{ hint.label }}</button>
        </div>
    </div>
</div>
<lat-photo-gallery-modal #photoGalleryModal [photos]="currentHomePhotos" *ngIf="currentHomePhotos?.length">
</lat-photo-gallery-modal>
<lat-floorplan-modal #floorplanModal [floorPlanImages]="currentHomeFloorplan" *ngIf="currentHomeFloorplan?.length">
</lat-floorplan-modal>
<lat-virtual-tour-modal #virtualTourModal [iframeUrl]="currentHomeVirtualTourLink"
    *ngIf="currentHomeVirtualTourLink?.length"></lat-virtual-tour-modal>