export const FieldErrors = {
  requiredOnly: {
    required: 'This field is required.'
  },
  email: {
    required: 'This field is required.',
    email: 'Please use full email address with @ sign and domain.'
  },
  zip: {
    required: 'This field is required.',
    invalidZip: 'Please enter a valid zip code.'
  },
  phoneRequired: {
    required: 'This field is required.',
    invalidPhone: 'Please enter a valid phone number.'
  },
  phoneNotRequired: {
    invalidPhone: 'Please enter a valid phone number.'
  },
  fileRequired: {
    required: 'This field is required.',
    invalidFile: 'Invalid file type.'
  }
};
