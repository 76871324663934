
import { ChangeDetectorRef, Component, EventEmitter, Input } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenService } from 'src/app/services/screen-size.service';
import { ComponentBase } from 'src/app/utils/component-base-class.utils';
import { PhotoEntry } from './../photo-gallery-modal/gallery.constants';

@Component({
    selector: 'lat-floorplan-modal',
    templateUrl: './floorplan.component.html',
    styleUrls: ['./floorplan.component.scss']
})
export class FloorPlanModalComponent extends ComponentBase {
    @Input() floorPlanImages: Array<PhotoEntry> = [];

    active: boolean = false;
    loaded: boolean = false;
    index: number = 0;

    modalOpenStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private screenService: ScreenService,
        private navigationService: NavigationService,
        private observer: ChangeDetectorRef,
    ) {
        super(screenService, navigationService);
    }

    ngOnInit(): void { }

    photoLoaded(evt: Event): void {
        this.loaded = true;
        this.observer.detectChanges();
    }

    public openModal(): void {
        this.active = true;
        this.loaded = false;
        this.observer.detectChanges();
        this.modalOpenStateChanged.emit(true);
    }

    public closeModal(): void {
        this.active = false;
        this.loaded = false;
        this.index = 0;
        this.observer.detectChanges();
        this.modalOpenStateChanged.emit(false);
    }

    public next(): void {
        if (this.index < this.floorPlanImages.length - 1) {
            this.loaded = false;
            this.index = this.index + 1;
            this.observer.detectChanges();
        }
    }
    public prev(): void {
        if (this.index > 0) {
            this.loaded = false;
            this.index = this.index - 1;
            this.observer.detectChanges();
        }
    }
}