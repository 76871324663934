import { environment } from "src/environments/environment";

export const SplashData = {
    backgroundImages: [
        `${environment.S3_URL}images/photos/homepage/header_1.png`,
        `${environment.S3_URL}images/photos/homepage/header_2.png`,
        `${environment.S3_URL}images/photos/homepage/header_3.png`,
        `${environment.S3_URL}images/photos/homepage/header_4.png`,
        `${environment.S3_URL}images/photos/homepage/header_5.png`,
    ]
};