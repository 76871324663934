<div class="photo-gallery-modal" *ngIf="active">
    <div class="photo-gallery-modal-content">
        <div class="photo-gallery-modal-photo-container">
            <div class="spinner-container" *ngIf="!loaded">
                <mat-spinner></mat-spinner>
            </div>
            <img
                [ngStyle]="{ display: loaded ? 'unset' :  'none' }"
                class="photo-gallery-modal-photo"
                (load)="photoLoaded($event)"
                [src]="photos[index]?.url"
            />
            <div class="photo-gallery-modal-photo-caption">{{ photos[index]?.caption }}</div>
        </div>
        <div class="photo-gallery-modal-controls">
            <div class="photo-gallery-modal-nav-container">
                <div
                    (click)="prev()"
                    class="photo-gallery-modal-nav photo-gallery-modal-nav-prev">
                    <img src="/assets/icons/arrows/Arrow_Left_Orange.svg"> 
                </div>
                <div
                    (click)="next()"
                    class="photo-gallery-modal-nav photo-gallery-modal-nav-next">
                    <img src="/assets/icons/arrows/Arrow_Right_Orange.svg">
                </div>
            </div>
            <div class="photo-gallery-modal-counter">{{ index + 1 }} of {{ photos.length }}</div>
        </div>
    </div>
    <div class="photo-gallery-modal-close-container">
        <button class="photo-gallery-modal-close-button" (click)="closeModal()">Close</button>
    </div>
</div>