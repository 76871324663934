<swiper [config]="slideConfig">
  <div class="swiper-wrapper">
    <div *ngFor="let slide of slideData; let i = index" class="swiper-slide">
      <div
        [ngStyle]="{
          'background-image':
            'url(' +
            (isMobile || isTablet
              ? slide?.mobileImageSrc
              : slide?.desktopImageSrc) +
            ')'
        }"
      ></div>
    </div>
  </div>
  <div
    class="swiper-pagination"
    [style]="
      'position: absolute; bottom: ' +
      (isMobile ? '30' : isTablet ? '25' : '5') +
      'vw;'
    "
  ></div>
</swiper>
