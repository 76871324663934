import { HttpClientModule } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { DomSanitizer } from '@angular/platform-browser';

const MaterialComponents = [
  MatTableModule,
  MatIconModule,
  MatSortModule,
  MatPaginatorModule,
  MatSelectModule,
  MatDialogModule,
  MatButtonModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatCardModule,
  MatCheckboxModule,
  MatTabsModule,
  MatMenuModule,
  MatToolbarModule,
  MatBottomSheetModule,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatExpansionModule
];

@NgModule({
  declarations: [],
  imports: [MaterialComponents, HttpClientModule],
  exports: [MaterialComponents, HttpClientModule]
})
export class MaterialModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'magnify',
      sanitizer.bypassSecurityTrustResourceUrl('assets/magnify_navy.svg')
    );
    iconRegistry.addSvgIcon(
      'hide_eye',
      sanitizer.bypassSecurityTrustResourceUrl('assets/Hide_PW.svg')
    );
    iconRegistry.addSvgIcon(
      'show_eye',
      sanitizer.bypassSecurityTrustResourceUrl('assets/Show_PW.svg')
    );
  }
}
