import { BehaviorSubject } from 'rxjs';

import { ScreenService } from './../services/screen-size.service';

export class BreakpointsUtils {
  public aBreakpointWasCrossed$ = new BehaviorSubject(null);

  constructor(private __screenService: ScreenService) {
    this.__screenService.isMobile$.subscribe(next => {
      this.isMobile = next;
      this.aBreakpointWasCrossed$.next(Date.now());
    });
    this.__screenService.isTablet$.subscribe(next => {
      this.isTablet = next;
      this.aBreakpointWasCrossed$.next(Date.now());
    });
    this.__screenService.isDesktop$.subscribe(next => {
      this.isDesktop = next;
      this.aBreakpointWasCrossed$.next(Date.now());
    });
  }

  public isMobile: boolean;
  public isTablet: boolean;
  public isDesktop: boolean;

  // Use for classes that are already extended 1x.
  // classInstance must have screenService injected
  public static subscribeForClass(classInstance): void {
    (classInstance.screenService as ScreenService).isMobile$.subscribe(
      val => (classInstance.isMobile = val)
    );
    (classInstance.screenService as ScreenService).isTablet$.subscribe(
      val => (classInstance.isTablet = val)
    );
    (classInstance.screenService as ScreenService).isDesktop$.subscribe(
      val => (classInstance.isDesktop = val)
    );
  }
}
