<header class="header">
  <nav class="navigation">
    <div class="logo" routerLinkActive="active" routerLink="/">
      <img src="/assets/icons/nav/Home.svg" alt="Homes Icon" />
    </div>
    <div class="navigation-bar-link-container">
      <div
      class="navigation-bar-link"
      routerLink="{{ navBarLinks[0].link }}"
      [ngStyle]="{ 'margin-right': linkMargin }"
      >
        <span [ngClass]="{ 'active-page-link': currentPage === '' ||
          navBarLinks[0].link.includes(this.currentPage) }">
          {{ navBarLinks[0].name }}
        </span>
        <div
          class="active-page-swish"
          *ngIf="currentPage === '' || navBarLinks[0].link.includes(this.currentPage)" 
          src="/assets/icons/swish/White.svg"
        ></div>
      </div>
      <div
        *ngFor="let navLink of navBarLinks.slice(1).filter(linkEnabled)"
        class="navigation-bar-link"
        routerLink="{{ navLink.link }}"
        [ngStyle]="{ 'margin-right': linkMargin }"
      >
        <span
          [ngClass]="{ 
            'active-page-link': currentPage !== '' && navLink.link.includes(this.currentPage),
            'navigation-bar-link-disabled': navLink.disabled
          }"
        >
          {{ navLink.name }}
        </span>
        <div
          class="active-page-swish"
          *ngIf="currentPage !== '' && navLink.link.includes(this.currentPage)" 
        ></div>
      </div>
    </div>
    <div class="logo-container" routerLinkActive="active" routerLink="/">
      <img class="lat-logo" src="/assets/logos/lakeside-at-trappe/LAT-logo-NoIcon.svg" alt="LAT Logo" />
      <div class="eho-container">
        <span class="eho-member-slug">MHBR #408</span>
        <img class="eho-icon" src="/assets/logos/eho/EHO-logo.svg">
      </div>
    </div>
  </nav>
</header>
