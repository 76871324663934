// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  SITE_PLAN_URL: 'https://stagesiteplans.brookfielddc.com/lakeside-at-trappe-planned-community',
  url: '',
  APP_URL: 'https://lakesideattrappe-commtouch-dev.ndgcommunications.com',
  API_URL: 'https://stagerealresults.brookfielddc.com/rest/brp.api/realresults',
  S3_URL: 'https://lat-community-touchscreen-development.s3.amazonaws.com/',
  GOOGLE_MAPS_API_KEY: 'AIzaSyD_ms5dBMe5-QlxJQ_4DrTtUPN_PTVImD8',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
