<div
    class="site-plan-container"
    [ngStyle]="hideNav ? { height: '1080px' } : {}"
>
    <h1>SITE PLAN</h1>
    <div class="site-plan-map-container">
        <div class="site-plan-legend-gutter">
            <div
                *ngFor="let elem of legendLabels"
                class="legend-element">
                <div class="legend-swatch" [ngStyle]="{ 'background-color': elem.color }"></div>
                <div class="legend-label">{{ elem.label }}</div>
            </div>
        </div>
        <iframe class="site-plan-map" [src]="iframeUrl"></iframe>
    </div>
</div>