import { Component, OnInit } from '@angular/core';
import { SplashData } from 'src/app/data/splash.data';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenService } from 'src/app/services/screen-size.service';
import { ComponentBase } from 'src/app/utils/component-base-class.utils';

@Component({
  selector: 'lat-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent extends ComponentBase implements OnInit {
  splashData = SplashData;

  constructor(
    private screenService: ScreenService,
    private navigationService: NavigationService
  ) {
    super(screenService, navigationService);
  }

  ngOnInit(): void {
  }
}
