import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentBase } from 'src/app/utils/component-base-class.utils';
import { HomeSelectorTile } from 'src/app/types/home-category';
import { EventHint } from 'src/app/types/home-swiper';
import { ScreenService } from 'src/app/services/screen-size.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
    selector: 'lat-home-category-selector',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss']
})
export class HomeCategorySelectorComponent extends ComponentBase {
    @Input() tiles: Array<HomeSelectorTile>

    @Output() onSectionChange: EventEmitter<EventHint> = new EventEmitter<EventHint>();

    constructor(
        private changeObserver: ChangeDetectorRef,
        private screenService: ScreenService,
        private navigationService: NavigationService
    ) {
        super(screenService, navigationService);
    }

    ngOnInit(): void { }

    selectSection(section: string): void {
        this.onSectionChange.emit({ id: section });
    }
}
