import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommunityNavBarLinks, NavBarLink } from 'src/app/data/navbar.data';
import { NavigationService } from 'src/app/services/navigation.service';
import { ScreenService } from 'src/app/services/screen-size.service';
import { ComponentBase } from 'src/app/utils/component-base-class.utils';

@Component({
  selector: 'lat-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends ComponentBase implements OnInit {
  @Input() navBarLinks: Array<NavBarLink>;
  @Input() currentPage: string;
  @Input() linkMargin: string = '80px';
  constructor(
    private router: Router,
    private screenService: ScreenService,
    private navigationService: NavigationService
  ) {
    super(screenService, navigationService);
  }

  ngOnInit(): void { }

  linkEnabled(link: NavBarLink): boolean {
    return !link.disabled
  }
}
